<template>
	<div>
		<b-modal
			ref="modal-description-petty-cash"
			@hidden="$emit('close')"
			:title="getTitle"
			cancel-title="Cancelar"
			scrollable
		>
			<div>
				<ValidationObserver ref="form-description-petty-cash">
					<b-row>
						<b-col sm="12" md="12">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group :state="errors[0] ? false : null">
									<div class="d-flex justify-content-between align-items-center">
										<label for="category_id">Categoria</label>
										<feather-icon
											icon="PlusIcon"
											class="text-success cursor-pointer"
											size="19"
											@click="addCategory"
										/>
									</div>
									<v-select
										input-id="category_id"
										:clearable="false"
										v-model="form.category_id"
										label="name"
										:options="categories"
										:reduce="(cc) => cc.id"
										appendToBody
										:calculatePosition="positionDropdown"
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
						<b-col md="12">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group>
									<label for="description">
										Subcategoria
										<span class="text-danger">(*)</span>
									</label>
									<b-form-input
										id="description"
										:state="errors[0] ? false : null"
										type="text"
										v-model="form.name"
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
					</b-row>
				</ValidationObserver>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
				<b-button variant="primary" @click="registerDescription">Guardar</b-button>
			</template>
		</b-modal>

		<CategoryModal v-if="categoryModal" @close="categoryModal = false" @refresh="refreshCategory" />
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import CategoryModal from "@/views/amg/management/views/petty-cash/components/CategoryModal.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"

export default {
	props: {
		origin: {
			default: "petty-cash",
		},
		info: {
			default: "",
		},
		transaction: {
			default: "",
		},
	},
	directives: { Ripple },
	components: { CategoryModal },
	mixins: [modalMixin],
	data() {
		return {
			categories: [],
			form: {
				id: null,
				transaction_id: null,
				category_id: null,
				name: null,
				origin: null,
			},
			categoryModal: false,
		}
	},
	computed: {
		getTitle() {
			if (this.origin == "petty-cash") {
				return "Agregar Descripcion"
			}
			return "Editar Descripcion"
		},
	},
	async mounted() {
		this.toggleModal("modal-description-petty-cash")
	},
	async created() {
		try {
			this.form.name = this.origin == "petty-cash" ? this.transaction.description : this.info.name
			this.form.category_id = this.origin == "petty-cash" ? null : this.info.category_id
			this.form.transaction_id = this.origin == "petty-cash" ? this.transaction.id : null
			this.form.id = this.origin == "petty-cash" ? null : this.info.id
			this.form.origin = this.origin
			await Promise.all([this.getCategories()])
		} catch (error) {
			console.log(error)
		}
	},
	methods: {
		async getCategories() {
			try {
				const { data } = await PettyCashService.getCategories()
				this.categories = data
			} catch (error) {
				throw error
			}
		},

		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},

		addCategory() {
			this.categoryModal = true
		},

		async refreshCategory() {
			try {
				this.categoryModal = false
				await this.getCategories()
			} catch (error) {
				console.log(error)
			}
		},

		async registerDescription() {
			const validate = await this.$refs["form-description-petty-cash"].validate()
			if (!validate) return
			const confirm = await this.showConfirmSwal({
				text: "Agregar descripcion",
			})
			if (!confirm.isConfirmed) return

			this.isPreloading()

			try {
				const { data } = await PettyCashService.registerDescription(this.form)
				this.showSuccessToast("Descripcion registrado correctamente")
				this.$emit("refresh")
			} catch (error) {
				console.log(error)
				this.showSuccessToast(error.response?.data,"danger")
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>
