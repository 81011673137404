<template>
	<div>
		<b-modal
			ref="modal-category-petty-cash"
			@hidden="$emit('close')"
			:title="getTitle"
			cancel-title="Cancelar"
			scrollable
		>
			<div>
				<ValidationObserver ref="form-category-pc">
					<b-row>
						<b-col md="12">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group>
									<label for="category">
										Categoria
										<span class="text-danger">(*)</span>
									</label>
									<b-form-input
										id="category"
										:state="errors[0] ? false : null"
										type="text"
										v-model="form.name"
									/>
								</b-form-group>
							</ValidationProvider>
						</b-col>
					</b-row>
				</ValidationObserver>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
				<b-button variant="primary" @click="registerCategory">Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import { ValidationObserver } from "vee-validate"

export default {
	props: {},
	directives: { Ripple },
	components: {},
	mixins: [modalMixin],
	data() {
		return {
			form: {
				name: null,
			},
		}
	},
	computed: {
		getTitle() {
			return "Agregar categoria"
		},
	},
	async mounted() {
		this.toggleModal("modal-category-petty-cash")
	},
	async created() {},
	methods: {
		async registerCategory() {
			const validate = await this.$refs["form-category-pc"].validate()
			if (!validate) return
			const confirm = await this.showConfirmSwal({
				text: "Agregar categoria",
			})
			if (!confirm.isConfirmed) return

			this.isPreloading()
			try {
				const { data } = await PettyCashService.registerCategory(this.form)
				this.showSuccessToast("Categoria registrado correctamente")
				this.$emit("refresh")
			} catch (error) {
				throw error
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>
